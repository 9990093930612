import { commonCodesGetCommonCode} from "@/utils/commonCodes";

export function getCommonCodeName(code, value) {
  if (value.length === 0) {
    return null;
  }
  return commonCodesGetCommonCode(code)
    .filter((data) => {
      if (value.includes(data.comCode)) {
        return true;
      }
      return false;
    })
    .map((data) => data.comName);
}

export function getDataRangeValue(value) {
  if (!value.from || !value.to) {
    return null;
  }
  return value.from + "~" + value.to;
}

export const reservationSearchNameObj = {
  resveInfo: {
    title: "예약정보",
    dateDiv: {
      title: "일자구분",
      value: null,
      set dataSet(v) {
        this.value =
          [
            { comName: "예약일자", comCode: "RESVE" },
            { comName: "등록일자", comCode: "INSERT" },
          ].filter(data => data.comCode === v).map(data => data.comName).join("");
      },
    },
    resveDate: {
      title: "일자",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = getDataRangeValue(v);
      },
    },
    resveTime: { title: "예약시간" },
    resveStartTime: {
      title: "예약시간 - 시작",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v;
      },
    },
    resveEndTime: {
      title: "예약시간 - 종료",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v;
      },
    },
    resveCourse: {
      title: "예약코스",
      code: "COURSE_CODE",
      value: null,
      set dataSet(v) {
        this.value = getCommonCodeName(this.code, v);
      },
    },
    bsnCode: {
      title: "영업구분",
      code: "BSN_CODE",
      value: null,
      set dataSet(v) {
        this.value = getCommonCodeName(this.code, v);
      },
    },
    dwCode: {
      title: "요일",
      code: "DW_CODE",
      value: null,
      set dataSet(v) {
        this.value = getCommonCodeName(this.code, v);
      },
    },
    resveCnt: {
      title: "예약횟수",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v;
      },
    },
    grpName: {
      title: "단체명",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v;
      },
    },
    grpKind: {
      title: "단체종류",
      code: "GRP_KIND",
      value: null,
      set dataSet(v) {
        this.value = getCommonCodeName(this.code, v);
      },
    },
    resveName: {
      title: "예약자명",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v;
      },
    },
    memberNo: {
      title: "회원번호",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v;
      },
    },
    memberGrade: {
      title: "회원등급",
      code: "MEMBER_GRADE",
      value: null,
      set dataSet(v) {
        this.value = getCommonCodeName(this.code, v);
      },
    },
    memberDiv: {
      title: "회원구분",
      code: "MEMBER_DIV",
      value: null,
      set dataSet(v) {
        this.value = getCommonCodeName(this.code, v);
      },
    },
    resveChannel: {
      title: "예약채널",
      code: "RESVE_CHANNEL",
      value: null,
      set dataSet(v) {
        this.value = getCommonCodeName(this.code, v);
      },
    },
    resveKind: {
      title: "예약종류",
      code: "RESVE_KIND",
      value: null,
      set dataSet(v) {
        this.value = getCommonCodeName(this.code, v);
      },
    },
    contactName: {
      title: "연락자명",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v;
      },
    },
    contactTelIdx: {
      title: "연락처 뒤 4자리",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v;
      },
    },
    resveRemarks: {
      title: "예약비고",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v;
      },
    },
    smsSendFlag: {
      title: "SMS전송여부",
      code: null,
      customCode: [
        { comCode: true, comName: "전송" },
        { comCode: false, comName: "미전송" },
      ],
      value: null,
      set dataSet(v) {
        this.value = v.length > 0 ? v.map(data => (data === true ? "전송" : (data === false ? "미전송" : null))) : null;
      },
    },
    areaCode: {
      title: "지역",
      code: "AREA_CODE",
      value: null,
      set dataSet(v) {
        this.value = getCommonCodeName(this.code, v);
      }
    },
    sexCode: {
      title: "성별",
      code: "SEX_CODE",
      value: null,
      set dataSet(v) {
        this.value = getCommonCodeName(this.code, v);
      }
    },
    recomendr: {
      title: "추천인",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v;
      }
    },
    vipFlag: {
      title: "VIP여부",
      code: null,
      customCode: [
        { comCode: true, comName: "예" },
        { comCode: false, comName: "아니오" },
      ],
      value: null,
      set dataSet(v) {
        this.value = v.length > 0 ? v.map(data => (data === true ? "예" : (data === false ? "아니오" : null))) : null;
      },
    },
    resveExFlag: {
      title: "기예약제외",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v ? "체크" : null;
      },
    },
    resveExDate: {
      title: "기예약제외 일자",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = getDataRangeValue(v);
      },
    },
    resvePenaltyFlag: {
      title: "위약제외",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v ? "체크" : null;
      },
    },
    resvePenaltyDate: {
      title: "위약제외 일자",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = getDataRangeValue(v);
      },
    },
    isAditTime: {
      title: "추가팀 보기",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v ? "체크" : null;
      },
    }
  }
};