<template>
  <div class="content-wrapper">
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section
          :class="[
            'article-section',
            'section-01',
            'accordion',
            { open: !isSearchSectionVisible },
          ]"
        >
          <div class="section-header">
            <div class="header-left">
              <div class="header-title" @click="handleSearchSectionHeaderClick">
                예약 조건 검색
              </div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="lookup keyColor">
                  <erp-button
                      button-div="GET"
                      :is-shortcut-button="true"
                      :disabled="isLoading"
                      :ignore="isPopupOpened"
                      @click.native="onSearchClick"
                  >
                    조회
                  </erp-button>
                </li>
                <li class="reset">
                  <erp-button
                      button-div="GET"
                      :is-icon-custom="true"
                      @click.native="searchInit"
                  >
                    초기화
                  </erp-button>
                </li>
                <li>
                  <erp-button
                      button-div="FILE"
                      @click.native="onExcelClick"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="section-body"
            style="display: flex; flex-direction: column"
          >
            <div style="flex: 1; overflow: auto">
              <article class="body-article" style="padding: 0">
                <section class="article-section" style="padding: 0">
                  <div class="section-body" style="padding: 18px; border: none">
                    <div class="body-data outerData">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field" style="width: calc(100% + 1px)">
                              <!-- 필수 : required -->
                              <div class="title outerDataTitle">
                                <div class="outerDataTitleItem">
                                  {{
                                    vueReservationSearchNameObj.resveInfo.title
                                  }}
                                </div>
                              </div>
                              <div class="content">
                                <div
                                  class="body-data innerData"
                                  style="margin: -1px"
                                >
                                  <div class="data-outer">
                                    <div class="data-inner">
                                      <ul class="data-content">
                                        <li class="field" style="width: calc((100% / 3) - 49px)">
                                          <!-- 필수 : required -->
                                          <div class="title dropdown">
                                            <ul class="row">
                                              <li class="item form-group select">
                                                <div class="form">
                                                  <ejs-dropdownlist
                                                    v-model="searchOption.resveInfo.dateDiv"
                                                    :dataSource="dateDivOptions"
                                                    :allowFiltering="false"
                                                    :fields="dateDivFields"
                                                    cssClass="lookup-condition-dropdown"
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <input-date-range
                                                    v-model="
                                                      searchOption
                                                        .resveInfo.resveDate
                                                    "
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field" style="width: calc((100% / 3) - 49px)">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueReservationSearchNameObj.resveInfo
                                                .resveTime.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li
                                                class="item form-group"
                                                style="width: calc((100% - 21px) / 2);border-right: none;"
                                              >
                                                <div class="form">
                                                  <input-time
                                                    v-model="
                                                      searchOption
                                                        .resveInfo
                                                        .resveStartTime
                                                    "
                                                    :format="'HH:mm'"
                                                    @change="
                                                      onChangeResveTime
                                                    "
                                                  />
                                                </div>
                                              </li>
                                              <li
                                                class="item form-group"
                                                style="width: 21px;border-right: none;"
                                              >
                                                ~
                                              </li>
                                              <li
                                                class="item form-group"
                                                style="width: calc((100% - 21px) / 2);"
                                              >
                                                <div class="form">
                                                  <input-time
                                                    v-model="
                                                      searchOption
                                                        .resveInfo
                                                        .resveEndTime
                                                    "
                                                    :format="'HH:mm'"
                                                    @change="
                                                      onChangeResveTime
                                                    "
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field" style="width: calc((100% / 3) + 101px)">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueReservationSearchNameObj.resveInfo
                                                .resveCourse.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    searchOption
                                                      .resveInfo.resveCourse
                                                  "
                                                  :code="
                                                    vueReservationSearchNameObj
                                                      .resveInfo.resveCourse.code
                                                  "
                                                  :textColor="true"
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueReservationSearchNameObj.resveInfo
                                                .bsnCode.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    searchOption
                                                      .resveInfo.bsnCode
                                                  "
                                                  :code="
                                                    vueReservationSearchNameObj
                                                      .resveInfo.bsnCode.code
                                                  "
                                                  :except-code="['CLOSE']"
                                                  :selectAll="false"
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueReservationSearchNameObj.resveInfo
                                                .dwCode.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    searchOption
                                                      .resveInfo.dwCode
                                                  "
                                                  :code="
                                                    vueReservationSearchNameObj
                                                      .resveInfo.dwCode.code
                                                  "
                                                  :textColor="true"
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li
                                          class="field"
                                          style="width: calc(100% + 1px)"
                                        >
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueReservationSearchNameObj.resveInfo
                                                .resveKind.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    searchOption
                                                      .resveInfo.resveKind
                                                  "
                                                  :code="
                                                    vueReservationSearchNameObj
                                                      .resveInfo.resveKind
                                                      .code
                                                  "
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li
                                          class="field"
                                          style="width: calc(100% + 1px)"
                                        >
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueReservationSearchNameObj.resveInfo
                                                .resveChannel.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    searchOption
                                                      .resveInfo.resveChannel
                                                  "
                                                  :code="
                                                    vueReservationSearchNameObj
                                                      .resveInfo.resveChannel
                                                      .code
                                                  "
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueReservationSearchNameObj.resveInfo
                                                .resveName.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <input-text
                                                    v-model="
                                                      searchOption
                                                        .resveInfo.resveName
                                                    "
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueReservationSearchNameObj.resveInfo
                                                .grpName.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <input-text
                                                    v-model="
                                                      searchOption
                                                        .resveInfo.grpName
                                                    "
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueReservationSearchNameObj.resveInfo
                                                .grpKind.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    searchOption
                                                      .resveInfo.grpKind
                                                  "
                                                  :code="
                                                    vueReservationSearchNameObj
                                                      .resveInfo.grpKind.code
                                                  "
                                                  :textColor="true"
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueReservationSearchNameObj.resveInfo
                                                .memberNo.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <input-text
                                                    v-model="
                                                      searchOption
                                                        .resveInfo.memberNo
                                                    "
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li
                                          class="field"
                                          style="width: calc(((100% / 3) * 2) + 1px);"
                                        >
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueReservationSearchNameObj.resveInfo
                                                .memberGrade.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    searchOption
                                                      .resveInfo.memberGrade
                                                  "
                                                  :code="
                                                    vueReservationSearchNameObj
                                                      .resveInfo.memberGrade.code
                                                  "
                                                  :textColor="true"
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li
                                          class="field"
                                          style="width: calc(100% + 1px)"
                                        >
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueReservationSearchNameObj.resveInfo
                                                .memberDiv.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    searchOption
                                                      .resveInfo.memberDiv
                                                  "
                                                  :code="
                                                    vueReservationSearchNameObj
                                                      .resveInfo.memberDiv.code
                                                  "
                                                  :textColor="true"
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueReservationSearchNameObj.resveInfo
                                                .contactName.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <input-text
                                                    v-model="
                                                      searchOption
                                                        .resveInfo.contactName
                                                    "
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueReservationSearchNameObj.resveInfo
                                                .contactTelIdx.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <input-text
                                                    v-model="
                                                      searchOption
                                                        .resveInfo.contactTelIdx
                                                    "
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueReservationSearchNameObj.resveInfo
                                                .resveRemarks.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <input-text
                                                    v-model="
                                                      searchOption
                                                        .resveInfo.resveRemarks
                                                    "
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueReservationSearchNameObj.resveInfo
                                                .smsSendFlag.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    searchOption
                                                      .resveInfo.smsSendFlag
                                                  "
                                                  :customCode="
                                                    vueReservationSearchNameObj
                                                      .resveInfo.smsSendFlag.customCode
                                                  "
                                                  :selectAll="false"
                                                  :textColor="true"
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueReservationSearchNameObj.resveInfo
                                                .sexCode.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    searchOption
                                                      .resveInfo.sexCode
                                                  "
                                                  :code="
                                                    vueReservationSearchNameObj
                                                      .resveInfo.sexCode.code
                                                  "
                                                  :selectAll="false"
                                                  :textColor="true"
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueReservationSearchNameObj.resveInfo
                                                .recomendr.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <input-text
                                                    v-model="
                                                      searchOption
                                                        .resveInfo.recomendr
                                                    "
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li
                                          class="field"
                                          style="width: calc(100% + 1px)"
                                        >
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueReservationSearchNameObj.resveInfo
                                                .areaCode.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    searchOption
                                                      .resveInfo.areaCode
                                                  "
                                                  :code="
                                                    vueReservationSearchNameObj
                                                      .resveInfo.areaCode.code
                                                  "
                                                  :textColor="true"
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueReservationSearchNameObj.resveInfo
                                                .vipFlag.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    searchOption
                                                      .resveInfo.vipFlag
                                                  "
                                                  :customCode="
                                                    vueReservationSearchNameObj
                                                      .resveInfo.vipFlag.customCode
                                                  "
                                                  :selectAll="false"
                                                  :textColor="true"
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="content title">
                                            <ul class="row">
                                              <li
                                                class="item form-group check"
                                              >
                                                <ul class="check">
                                                  <li>
                                                    <label>
                                                      <input
                                                        type="checkbox"
                                                        v-model="
                                                          searchOption
                                                            .resveInfo
                                                            .resveExFlag
                                                        "
                                                        @change="
                                                          onChangeResveExFlag
                                                        "
                                                      />
                                                      <i></i>
                                                      <div class="label">
                                                        {{
                                                          vueReservationSearchNameObj
                                                            .resveInfo
                                                            .resveExFlag.title
                                                        }}
                                                      </div>
                                                    </label>
                                                  </li>
                                                </ul>
                                              </li>
                                            </ul>
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <input-date-range
                                                    v-model="
                                                      searchOption
                                                        .resveInfo.resveExDate
                                                    "
                                                    :disabled="
                                                      !searchOption
                                                        .resveInfo.resveExFlag
                                                    "
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group check">
                                                <ul class="check">
                                                  <li>
                                                    <label>
                                                      <input
                                                        type="checkbox"
                                                        v-model="
                                                          searchOption
                                                            .resveInfo
                                                            .isAditTime
                                                        "
                                                      />
                                                      <i></i>
                                                      <div class="label">
                                                        {{
                                                          vueReservationSearchNameObj
                                                            .resveInfo
                                                            .isAditTime.title
                                                        }}
                                                      </div>
                                                    </label>
                                                  </li>
                                                </ul>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
            <div style="border-top: 1px solid #ccc;display: flex;flex-direction: row;">
              <div style="box-sizing: border-box;float: left;line-height: 19px;width: 82px;min-height: 27px;padding: 5px 5px 3px 5px;border-right: 1px solid #e0e0e0;display: flex;">
                <div style="width: 100%; text-align: center">검색조건</div>
              </div>
              <div style="box-sizing: border-box;float: left;line-height: 19px;width: calc(100% - 82px);min-height: 27px;padding: 5px 5px 3px 5px;flex: 1;">
                <div
                  v-for="(selectedData, i) in selectedData"
                  :key="selectedData.name"
                  style="float: left; padding: 0 4px"
                >
                  <div
                    style="float: left"
                    v-if="
                      i <
                      (isSearchSectionVisible ? 3 : 100)
                    "
                  >
                    {{ selectedData.name }} : {{ selectedData.data }}
                  </div>
                  <erp-button
                    button-div="CLOSE"
                    style="float: left;cursor: pointer;border: none;background: none;margin: 0;padding: 3px 6px 5px 6px;outline: none;"
                    :use-syncfusion-component-style="false"
                    @click="
                      deleteSelectedData(
                        selectedData.key,
                        selectedData.innerKey
                      )
                    "
                  >
                    <div class="iconClose"></div>
                  </erp-button>
                  <div style="clear: both; height: 0" />
                </div>
                <span
                  v-if="
                    isSearchSectionVisible &&
                    selectedData.length > 3
                  "
                >... 외 {{ selectedData.length - 3 }}건</span
                >
              </div>
              <div style="clear: both; height: 0" />
            </div>
          </div>
        </section>
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section
          :class="[
            'article-section',
            'section-02',
            { open: isSearchSectionVisible },
          ]"
        >
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">
                예약목록
              </div>
              <div class="header-caption">
                [ {{ totalReservations | numberWithCommas }} 건 ]
              </div>
            </div>
            <div class="header-caption"></div>
          </div>
          <div class="section-body" style="border: none">
            <grid
              ref="reservationSearchGrid"
              id="reservationSearchGrid"
              v-bind="reservationGridProps"
              @filtered="onReservationSearchGridDataFiltered"
              @sorted="onReservationSearchGridDataFiltered"
              @paged="onReservationSearchGridDataFiltered"
            />
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<style scoped>
.iconClose{display:block;float:left;width:11px;height:11px;border-radius:50%;background:#666 url(../../assets/images/common/multiselect-close.png) no-repeat center center}
.body-data.outerData + .body-data.outerData{margin-top:-1px}
.body-data.outerData .title.outerDataTitle{display:flex;align-items:center;border-top:1px solid #000}
.body-data.outerData .title.outerDataTitle .row{border-bottom:none;display:inline-block;line-height:1.5}
.body-data.outerData .title.outerDataTitle .item .check::before{display:none}
.body-data.outerData .title.outerDataTitle .outerDataTitleItem{width:100%;text-align:center}
.body-data.innerData{border-top:1px solid #000}
.body-data.innerData .data-content .field{width:calc((100% / 3) + 1px)}
.body-data.innerData .data-content .field .title{text-align:right}
.body-data.innerData .data-content .field .title.dropdown {padding: 0; background-color: #fff}
.body-data.innerData .data-content .field .content.title ul.row{float:right}
.body-data.innerData .data-content .field .content.title li.item{padding-right:12px}
.body-data.innerData .data-content .field .content.title .item .check::before{display:none}
.body-data.innerData .data-content .field .content.title .item .check .label{color:#000}
body .appContent .article-section.section-01.accordion.close > .section-body{flex:1;border:1px solid #ccc;border-top:1px solid #ccc!important}
body .appContent .body-article .article-section.section-01.open{height:416px}
body .appContent .body-article .article-section.section-01:not(.open){height:75px}
body .appContent .body-article .article-section.section-01:not(.open) > .section-body{border-top:0;overflow:hidden}
body .appContent .body-article .article-section.section-01:not(.open) > .section-body button{display:none}
body .appContent .body-article .article-section.section-02{height:calc(100% - 416px)}
body .appContent .body-article .article-section.section-02.open{height:calc(100% - 75px)}
body .article-section.accordion.open > .section-header .header-caption,body .article-section.accordion.open > .section-header .header-text,body .article-section.accordion.open > .section-header .header-label,body .article-section.accordion.open > .section-header .header-check,body .article-section.accordion.open > .section-header .header-button,body .article-section.accordion.open > .section-header .header-switch{display:block}
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import InputDateRange from "@/components/common/datetime/InputDateRange";
import InputTime from "@/components/common/datetime/InputTime";
import InputText from "@/components/common/text/InputText";
import CheckBoxRow from "@/components/common/CheckBoxRow";
import Grid from "@/components/grid/Grid";

import { reservationSearchNameObj } from "@/utils/reservationSearchInitData";
import {getFormattedDate} from "@/utils/date";
import { numberWithCommas } from "@/utils/number";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {commonCodesGetColorValue, commonCodesGetCommonCode, commonCodesGetSortNo } from "@/utils/commonCodes";
import {FORMAT_MEMBER_NUMBER, FORMAT_TEL_CELLPHONE_NUMBER} from "@/components/grid/GridCellDataFormatUtil";
import {mapActions, mapGetters} from "vuex";
// import { orderBy as _orderBy } from "lodash";

const searchInitOption = {
  resveInfo: {
    dateDiv: "RESVE",
    resveDate: { from: getFormattedDate(new Date()), to: getFormattedDate(new Date()) },
    resveStartTime: null,
    resveEndTime: null,
    resveCourse: [],
    bsnCode: [],
    dwCode: [],
    grpName: null,
    grpKind: [],
    resveName: null,
    memberNo: null,
    memberGrade: [],
    memberDiv: [],
    resveChannel: [],
    resveKind: [],
    contactName: null,
    contactTelIdx: null,
    resveRemarks: null,
    smsSendFlag: [],
    areaCode: [],
    sexCode: [],
    recomendr: null,
    vipFlag: [],
    resveExFlag: false,
    resveExDate: { from: null, to: null },
    isAditTime: false,
  },
};

export default {
  name: "SearchReservationConditions",
  components: {
    InputDateRange,
    InputTime,
    InputText,
    CheckBoxRow,
    Grid,
    ErpButton
  },
  mixins: [commonMixin, confirmDialogMixin],
  filters: {
    numberWithCommas,
  },
  data() {
    return {
      isSearchSectionVisible: false,
      isLoading: false,
      isSearched: false,
      totalReservations: 0,
      reservations: [],
      selectedData: [],
      vueReservationSearchNameObj: JSON.parse(JSON.stringify(reservationSearchNameObj)),
      searchOption: JSON.parse(JSON.stringify(searchInitOption)),
      dateDivOptions: [
        { text: "예약일자", value: "RESVE" },
        { text: "등록일자", value: "INSERT" },
      ],
      dateDivFields: { text: "text", value: "value" },
    };
  },
  created() {},
  watch: {
    searchOption: {
      deep: true,
      handler(data) {
        this.selectedData = [];
        Object.keys(data).forEach((key) => {
          Object.entries(data[key]).forEach(([innerKey, value]) => {
            reservationSearchNameObj[key][innerKey].dataSet = value;
            const data = reservationSearchNameObj[key][innerKey].value;
            if (data) {
              const selectedObj = {
                name:
                  reservationSearchNameObj[key].title +
                  ">" +
                  reservationSearchNameObj[key][innerKey].title,
                key,
                innerKey,
                data,
              };
              this.selectedData.push(selectedObj);
            }
          });
        });
      },
    },
  },
  computed: {
    ...mapGetters("documents", ["isSearchReservationConditionsExcelDownloading"]),
    isPopupOpened() {
      return false;
    },
    reservationGridProps() {
      let columns = [
        {
          field: "resveDate",
          name: "예약일자",
          width: 90,
          minWidth: 16,
          type: Date,
          cellStyle: {
            textAlign: "center",
          },
        },
        {
          field: "bsnCodeName",
          name: "영업구분",
          width: 90,
          minWidth: 16,
          type: String,
          cellStyle: {
            textAlign: "center",
          },
        },
        {
          field: "dwCodeName",
          name: "요일",
          width: 60,
          minWidth: 16,
          type: String,
          cellStyle: ({ dwCode }) => ({
            color: commonCodesGetColorValue("DW_CODE", dwCode),
            textAlign: "center",
          }),
        },
        {
          field: "resveTime",
          name: "예약시간",
          width: 90,
          minWidth: 16,
          type: String,
          cellStyle: {
            textAlign: "center",
          },
        },
        {
          field: "resveCourseName",
          name: "예약코스",
          width: 90,
          minWidth: 16,
          type: String,
          cellStyle: {
            textAlign: "center",
          },
        },
        {
          field: "resveKindName",
          name: "예약종류",
          width: 90,
          minWidth: 16,
          type: String,
          cellStyle: {
            textAlign: "center",
          },
        },
        {
          field: "resveChannelName",
          name: "예약채널",
          width: 90,
          minWidth: 16,
          type: String,
          cellStyle: {
            textAlign: "center",
          },
        },
        {
          field: "cancelDivName",
          name: "예약취소",
          width: 90,
          minWidth: 16,
          type: String,
          cellStyle: ({ cancelDiv }) => ({
            backgroundColor: commonCodesGetColorValue("CANCEL_DIV", cancelDiv),
            textAlign: "center",
            color: cancelDiv ? 'white' : 'black'
          }),
        },
        {
          field: "resveName",
          name: "예약자명",
          width: 90,
          minWidth: 16,
          type: String,
        },
        {
          field: "memberNo",
          name: "회원번호",
          width: 90,
          minWidth: 16,
          type: String,
          format: FORMAT_MEMBER_NUMBER,
          cellStyle: {
            textAlign: "center",
          },
        },
        {
          field: "grpName",
          name: "단체명",
          width: 90,
          minWidth: 16,
          type: String,
        },
        {
          field: "grpKindName",
          name: "단체종류",
          width: 90,
          minWidth: 16,
          type: String,
          cellStyle: ({ grpKind }) => ({
            color: commonCodesGetColorValue("GRP_KIND", grpKind),
            textAlign: "center",
          }),
        },
        {
          field: "memberNo",
          name: "회원번호",
          width: 90,
          minWidth: 16,
          type: String,
          cellStyle: {
            textAlign: "center",
          },
        },
        {
          field: "memberGradeName",
          name: "회원등급",
          width: 90,
          minWidth: 16,
          type: String,
          cellStyle: ({ memberGrade }) => ({
            color: commonCodesGetColorValue("MEMBER_GRADE", memberGrade),
            textAlign: "center",
          }),
        },
        {
          field: "memberDivName",
          name: "회원구분",
          width: 90,
          minWidth: 16,
          type: String,
          cellStyle: ({ memberDiv }) => ({
            color: commonCodesGetColorValue("MEMBER_DIV", memberDiv),
            textAlign: "center",
          }),
        },
        {
          field: "contactName",
          name: "연락자명",
          width: 90,
          minWidth: 16,
          type: String,
        },
        {
          field: "contactTel",
          name: "연락처",
          width: 110,
          minWidth: 16,
          type: String,
          format: FORMAT_TEL_CELLPHONE_NUMBER,
          cellStyle: {
            textAlign: "center",
          },
        },
        {
          field: "resveRemarks",
          name: "예약비고",
          width: 150,
          minWidth: 16,
          type: String,
        },
        {
          field: "smsSendFlag",
          name: "SMS전송여부",
          width: 110,
          minWidth: 16,
          type: Boolean,
          cellStyle: {
            textAlign: "center",
          },
        },
        {
          field: "sexCodeName",
          name: "성별",
          width: 90,
          minWidth: 16,
          type: String,
          cellStyle: {
            textAlign: "center",
          },
        },
        {
          field: "recomendr",
          name: "추천인",
          width: 90,
          minWidth: 16,
          type: String,
        },
        {
          field: "areaCodeName",
          name: "지역",
          width: 90,
          minWidth: 16,
          type: String,
        },
        {
          field: "vipFlag",
          name: "VIP여부",
          width: 90,
          minWidth: 16,
          type: Boolean,
          cellStyle: {
            textAlign: "center",
          },
        },
        {
          field: "confirmInsertName",
          name: "등록자",
          width: 90,
          minWidth: 16,
          type: String,
          cellStyle: {
            textAlign: "left",
          },
        },
        {
          field: "confirmInsertDt",
          name: "등록일시",
          width: 130,
          minWidth: 16,
          type: String,
          cellStyle: {
            textAlign: "center",
          },
        },
      ];

      return {
        columns,
        records: this.reservations,
        sortable: this.isSearched,
        filterable: this.isSearched,
        sizeOfRecordPerPage: 100,
        countOfAllRecords: this.totalReservations,
        rangeOfPages: 10,
      };
    },
  },
  methods: {
    ...mapActions("documents", ["downloadExcelSearchReservationConditions"]),
    handleSearchSectionHeaderClick() {
      this.isSearchSectionVisible = !this.isSearchSectionVisible;
    },
    onExcelClick() {
      if (50000 < this.totalReservations) {
        this.errorToast("데이터가 너무 많습니다. 5만건 이하로 시도해주세요.");
        return;
      }

      const searchOptions = this.makeSearchOptions();

      const columns = this.$refs["reservationSearchGrid"].getColumns();

      searchOptions.columns = columns;

      this.downloadExcelSearchReservationConditions({
        payload: searchOptions,
      });
    },
    searchInit() {
      this.searchOption = JSON.parse(JSON.stringify(searchInitOption));
    },
    makeSearchOptions() {
      const limit = this.reservationGridProps.sizeOfRecordPerPage;
      const { page, filters, orders } = this.$refs[
        "reservationSearchGrid"
        ].getOrdersAndFiltersAndPage();

      const _orders = JSON.parse(JSON.stringify(orders || []));
      // if (_orders.findIndex(({ field }) => field === "resveDate") < 0) {
      //   _orders.push({
      //     field: "resveDate",
      //     direction: "asc",
      //   });
      // }
      if (_orders.length === 0) {
        _orders.push({
          field: "resveDate",
          direction: "asc",
        });
        _orders.push({
          field: "resveTime",
          direction: "asc",
        });
        _orders.push({
          field: "resveCourse",
          direction: "asc",
        });
        _orders.push({
          field: "cancelDivName",
          direction: "asc",
        });
      }

      return {
        limit,
        offset: (page - 1) * limit,
        filters: filters.length > 0 ? filters : undefined,
        orders: _orders.length > 0 ? _orders : undefined,
        dateDiv: this.searchOption.resveInfo.dateDiv || "RESVE",
        resveDateFrom: this.searchOption.resveInfo.resveDate.from || undefined,
        resveDateTo: this.searchOption.resveInfo.resveDate.to || undefined,
        resveTimeFrom: this.searchOption.resveInfo.resveStartTime || undefined,
        resveTimeTo: this.searchOption.resveInfo.resveEndTime || undefined,
        resveCourseList:
          this.searchOption.resveInfo.resveCourse.length > 0 &&
          this.searchOption.resveInfo.resveCourse.length !==
          commonCodesGetCommonCode("COURSE_CODE")?.length
            ? this.searchOption.resveInfo.resveCourse
            : null,
        bsnCode: this.searchOption.resveInfo.bsnCode.length === 1 ? this.searchOption.resveInfo.bsnCode.join("") : null,
        dwCodeList:
          this.searchOption.resveInfo.dwCode.length > 0 &&
          this.searchOption.resveInfo.dwCode.length !==
          commonCodesGetCommonCode("DW_CODE")?.length
            ? this.searchOption.resveInfo.dwCode
            : null,
        grpName: this.searchOption.resveInfo.grpName || undefined,
        grpKindList:
          this.searchOption.resveInfo.grpKind.length > 0 &&
          this.searchOption.resveInfo.grpKind.length !==
          commonCodesGetCommonCode("GRP_KIND")?.length
            ? this.searchOption.resveInfo.grpKind
            : undefined,
        resveName: this.searchOption.resveInfo.resveName || undefined,
        memberNo: this.searchOption.resveInfo.memberNo || undefined,
        memberGradeList:
          this.searchOption.resveInfo.memberGrade.length > 0 &&
          this.searchOption.resveInfo.memberGrade.length !==
          commonCodesGetCommonCode("MEMBER_GRADE")?.length
            ? this.searchOption.resveInfo.memberGrade
            : undefined,
        memberDivList:
          this.searchOption.resveInfo.memberDiv.length > 0 &&
          this.searchOption.resveInfo.memberDiv.length !==
          commonCodesGetCommonCode("MEMBER_DIV")?.length
            ? this.searchOption.resveInfo.memberDiv
            : undefined,
        resveChannelList:
          this.searchOption.resveInfo.resveChannel.length > 0 &&
          this.searchOption.resveInfo.resveChannel.length !==
          commonCodesGetCommonCode("RESVE_CHANNEL")?.length
            ? this.searchOption.resveInfo.resveChannel
            : undefined,
        resveKindList:
          this.searchOption.resveInfo.resveKind.length > 0 &&
          this.searchOption.resveInfo.resveKind.length !==
          commonCodesGetCommonCode("RESVE_KIND")?.length
            ? this.searchOption.resveInfo.resveKind
            : undefined,
        contactName: this.searchOption.resveInfo.contactName || undefined,
        contactTelIdx: this.searchOption.resveInfo.contactTelIdx || undefined,
        resveRemarks: this.searchOption.resveInfo.resveRemarks || undefined,
        smsSendFlag: this.searchOption.resveInfo.smsSendFlag.length === 1 ? this.searchOption.resveInfo.smsSendFlag.join("") : undefined,
        areaCodeList:
          this.searchOption.resveInfo.areaCode.length > 0 &&
          this.searchOption.resveInfo.areaCode.length !==
          commonCodesGetCommonCode("AREA_CODE")?.length
            ? this.searchOption.resveInfo.areaCode
            : undefined,
        sexCode: this.searchOption.resveInfo.sexCode.length === 1 ? this.searchOption.resveInfo.sexCode.join("") : undefined,
        recomendr: this.searchOption.resveInfo.recomendr || undefined,
        vipFlag: this.searchOption.resveInfo.vipFlag.length === 1 ? this.searchOption.resveInfo.vipFlag.join("") : undefined,
        excludeExistReservationDateFrom:
          this.searchOption.resveInfo.resveExFlag &&
          this.searchOption.resveInfo.resveExDate &&
          this.searchOption.resveInfo.resveExDate.from
            ? this.searchOption.resveInfo.resveExDate.from
            : undefined,
        excludeExistReservationDateTo:
          this.searchOption.resveInfo.resveExFlag &&
          this.searchOption.resveInfo.resveExDate &&
          this.searchOption.resveInfo.resveExDate.to
            ? this.searchOption.resveInfo.resveExDate.to
            : undefined,
        isAditTime: this.searchOption.resveInfo.isAditTime || false,
      };
    },
    async onSearchClick() {
      console.log('onSearchClick.');
      this.$refs["reservationSearchGrid"].resetPage();

      await this.fetchReservationReceptionRecords();

      if (
        this.$refs["reservationSearchGrid"].getSelectedRows().length < 1
      ) {
        this.$refs["reservationSearchGrid"].selectRow(0);
      }
    },
    async fetchReservationReceptionRecords() {
      if (
        !this.searchOption.resveInfo.resveDate.from ||
        !this.searchOption.resveInfo.resveDate.to
      ) {
        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["예약일자"])
        );
      }
      if (
        this.searchOption.resveInfo.resveExFlag &&
        (!this.searchOption.resveInfo.resveExDate.from ||
        !this.searchOption.resveInfo.resveExDate.to)
      ) {
        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["기예약제외"])
        );
      }

      this.isLoading = true;

      try {
        const searchOptions = this.makeSearchOptions();
        const result = await GolfErpAPI.fetchReservationReceptionRecords(searchOptions);

        // this.reservations = _orderBy(result.records.map(record => ({
        //   ...record,
        //   courseCodeSortNo: commonCodesGetSortNo("COURSE_CODE", record.resveCourse),
        // })), ["resveDate", "resveTime", "courseCodeSortNo"]);

        this.reservations = result.records.map(record => ({
          ...record,
          courseCodeSortNo: commonCodesGetSortNo("COURSE_CODE", record.resveCourse),
        }));
        this.totalReservations = result.total;
        this.isSearched = true;
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    onChangeResveTime() {
      if (
        (!this.searchOption.resveInfo.resveStartTime ||
          this.searchOption.resveInfo.resveStartTime === "00:00") &&
        (!this.searchOption.resveInfo.resveEndTime ||
          this.searchOption.resveInfo.resveEndTime === "00:00")
      ) {
        this.searchOption.resveInfo.resveStartTime = null;
        this.searchOption.resveInfo.resveEndTime = null;
      }
    },
    onChangeResveExFlag() {
      if (!this.searchOption.resveInfo.resveExFlag) {
        this.searchOption.resveInfo.resveExDate.from = null;
        this.searchOption.resveInfo.resveExDate.to = null;
      }
    },
    deleteSelectedData(key, innerKey) {
      this.searchOption[key][innerKey] = JSON.parse(
        JSON.stringify(searchInitOption[key][innerKey])
      );
    },
    onReservationSearchGridDataFiltered(event) {
      if (!event) {
        return;
      }

      this.fetchReservationReceptionRecords();
    },
  }
};
</script>
